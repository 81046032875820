<template>
    <div class="container">
        <div class="header">
            <div class="row">
                <div class="col-12 text-center col-lg-8 order-lg-2">
                    <a href="/">
                        <img class="logo" :src="require('@/assets/logo.jpg')">
                    </a>
                    <span class="service-mark">SM</span>
                </div>
                <div class="col-6 text-center col-lg-2 order-lg-1 text-lg-start">
                    <img :src="require('@/assets/right-now-on-ebay.gif')">
                </div>
                <div class="col-6 text-center col-lg-2 order-lg-3 text-lg-end">
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div class="mx-1">
                            <a class="ebay-link" :href="redirectURL" target="_blank">
                                {{ country.site }}
                                <br>
                                {{ country.name }}
                            </a>
                        </div>
                        <div class="mx-1">
                            <img :src="require('@/assets/flags/' + country.code + '.jpg')">
                        </div>
                    </div>
                    <div v-if="currentRouteName !== 'lookup-item'" class="row">
                        <div class="col text-center country-info">
                            (change country below)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts">
import {
    PropType,
    defineComponent
} from 'vue';

import {
    EbaySite
} from '@/types';
import {
    createRedirectURL
} from '@/utils/redirector';

export default defineComponent({
    name: 'Header',
    props: {
        country: {
            type: Object as PropType<EbaySite>,
            required: true
        }
    },
    data() {
        return {
            'redirectURL': createRedirectURL(this.country.site, '', '', '', '', '')
        }
    },
    computed: {
        currentRouteName(): string {
            return this.$route.name as string;
        }
    }
});
</script>

<style scoped>
    .header {
        padding: 20px;
    }
    .logo {
        max-width: 100%;
    }
    .service-mark {
        font-family: Arial,Verdana,Tahoma;
        font-size: 10pt;
        color: #b3b3b3;
    }
    .ebay-link {
        color: #7812cc;
        text-decoration: none;
        font-family: Verdana,Tahoma,Arial;
        font-weight: bold;
        font-size: 8pt;
    }
    .ebay-link:hover {
        color: #002699;
        text-decoration: underline;
    }
    .country-info {
        color: #b3b3b3;
        font-family: Tahoma,Verdana,Helvetica;
        font-size: 8pt;
    }
</style>
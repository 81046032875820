<template>
    <div class="search-presets">
        <div class="py-2 py-lg-0">
            <a class="preset-link" href="/mw2?listingType=all&status=live&sortBy=watchcount&sortOrder=desc&preset=mw">Most Watched</a>
        </div>
        <div class="py-2 py-lg-0">
            <a class="preset-link" href="/mw2?listingType=all&status=sold&sortBy=price&sortOrder=desc&site=EBAY_US&preset=s">Sold</a>
        </div>
        <div class="py-2 py-lg-0">
            <a class="preset-link" href="/mw2?listingType=auction&status=live&sortBy=bids&sortOrder=desc&preset=mb">Most Bids</a>
        </div>
        <div class="py-2 py-lg-0">
            <a class="preset-link" href="/mw2?listingType=all&status=live&sortBy=bestmatch&sortOrder=desc&preset=es">eBay Standard</a>
        </div>
        <div class="py-2 py-lg-0">
            <a class="preset-link" href="/mw2?listingType=auction&status=live&sortBy=enddate&sortOrder=asc&maxBids=0&preset=nbes">No Bids Ending Soon</a>
        </div>
    </div>
</template>

<script lang="ts">
import {
    defineComponent
} from "vue";

export default defineComponent({
    name: 'SearchPresets'
});
</script>

<style scoped>
    .search-presets {
        background-color: #f6f6ff;
    }

    .preset-link {
        color: #7812CC;
        text-decoration: none;
        font-family: Tahoma,Verdana,Helvetica;
        font-size: 8pt;
        font-weight: bold;
    }
    .preset-link:hover {
        color: #002699;
        text-decoration: underline;
    }
</style>
<template>
  <div class="row px-2">
    <div class="d-none d-lg-block col-auto pe-0">
      <DisplayBox title="Search">
        <SearchPresets></SearchPresets>
      </DisplayBox>
      <DisplayBox title="Categories">
        <TopCategories :categories="categories"></TopCategories>
      </DisplayBox>
    </div>
    <div class="offcanvas offcanvas-start d-block d-lg-none" id="offcanvas">
      <div class="offcanvas-header">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <DisplayBox title="Search">
          <SearchPresets></SearchPresets>
        </DisplayBox>
        <DisplayBox title="Categories">
          <TopCategories :categories="categories"></TopCategories>
        </DisplayBox>
      </div>
    </div>
    <div class="col px-0">
      <div class="container">
        <div v-if="showNotification" class="row px-3 py-3 py-lg-3">
          <div class="col-12 notif">
            <div class="row">
              <div class="col">
                  <strong>WatchCount Updates:</strong> You can now search for both Most Watched Live and Sold items using the same form below.  Bugs, feedback or requests? Email <a href="mailto:feedback@watchcount.com">feedback@watchcount.com</a>.
              </div>
              <div class="col-auto">
                <button type="button" class="btn-close me-2 m-auto" @click="closeNotification"></button>
              </div>
            </div>
          </div>
        </div>
        <DisplayBox title="eBay's Most Popular Live and Sold Items" :menu="true" :isHeader="false">
          <FormMostWatched
            :categories="categories"
            :subcategoryTree="subcategoryTree"
            :mostWatchedRequest="mostWatchedRequest"
            :loading="mostWatchedLoading"
            @updateMostWatchedRequest="mostWatchedRequest = $event"
            @submit.prevent="submitSearch"
            @reset="subcategoryTree = undefined"
          ></FormMostWatched>
          <CountrySelector :country="localCountry" @updateCountry="localCountry = $event"></CountrySelector>
          <div class="row">
            <div class="col-0 col-lg-4"></div>
            <div class="col-12 col-lg-4 text-center feedback-text">
              Have feedback? Please email us at <a href="mailto:feedback@watchcount.com">feedback@watchcount.com</a>
            </div>
            <div class="col-0 col-lg-4"></div>
          </div>
        </DisplayBox>
        <DisplayBox v-if="mostWatchedLoading || mostWatchedResponse" :title="title" :isHeader="true">
          <div v-if="mostWatchedLoading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <MostWatchedResults
            v-if="mostWatchedResponse !== null"
            :request="currMostWatchedRequest"
            :results="mostWatchedResponse"
          ></MostWatchedResults>
          <div v-if="mostWatchedResponse !== null" class="row my-3">
            <div class="col-12 text-center col-lg-6">
              <span class="pagination"><span>Showing Page&nbsp;</span> <span v-if="mostWatchedResponse.limit === 0">1</span><span v-else>{{ currMostWatchedRequest.offset / mostWatchedResponse.limit + 1 }}</span>: {{ mostWatchedResponse.items.length }} of {{ mostWatchedResponse.total.toLocaleString() }} items</span>
            </div>
            <div class="col-12 text-center col-lg-6">
              <div class="row">
                <div v-if="mostWatchedResponse.previousOffset !== null" class="col">
                  <a href="#" class="link" @click.prevent="search(mostWatchedResponse.previousOffset)">Previous</a>
                </div>
                <div v-if="mostWatchedResponse.nextOffset !== null" class="col">
                  <a href="#" class="link" @click.prevent="search(mostWatchedResponse.nextOffset)">Next</a>
                </div>
              </div>
            </div>
          </div>
        </DisplayBox>
        <template v-else>
          <DisplayBox v-if="mostWatchedRequest.status === 'sold'" title="eBay Sold Prices:  Search eBay’s Completed Items for Recent Sold Prices, Price History, Bids, Watchers, and more" :isHeader="true">
            <div class="container info">
              <p>Now you can search all of eBay’s sold prices and listings by keyword and category!</p>
              <p><strong>Buyers search eBay sold prices to make sure they always get a great deal! </strong></p>
              <p>When considering making a purchase, smart buyers research how many similar items have sold recently and at what prices.  Are the prices constant or always changing? Are they items sold frequently or tough to find? With this data, they know what it’s worth, how high an offer to make, or how much to bid!</p>
              <p><strong>Sellers search eBay sold prices to increase revenue and grow their business!</strong></p>
              <p>Savvy sellers discover new niches and identify new product opportunities by searching their keywords and categories and exploring the most popular items.  See which products in your vertical are selling well.  Find opportunities to increase your margins, expand your product lines, and optimize your eBay titles and listings!</p>
              <p><strong><i>Looking for the most popular live eBay listings and auctions?  Simply slide the toggle above to “Search Live.”</i></strong></p>
              <p><i>Buyers use Watchcount’s live eBay search to find awesome deals on <a href="/mw2">eBay’s most popular live auctions and fixed price listings</a> and <a href="/mw2">discover unique, one-of-a-kind items</a> from their favorite keywords & categories.</i></p>
              <p><i>Sellers also use <a href="/mw2">Watchcount’s live eBay search</a> to identify the most popular products for their keywords and categories, find opportunities to expand their product lines, and learn more about their competitors’ top listings.</i></p>
            </div>
          </DisplayBox>
          <template v-else>
            <DisplayBox title="eBay Most Watched/Most Popular Listings, Ranked by Watchers, Bids, Price or Best Match" :isHeader="true">
              <div class="container info">
                <p><strong>Easily find the most popular items for your favorite keywords and categories!</strong></p>
                <p>Search eBay with your results sorted by the number of watchers (“watch count”).  Watch counts are the best, most unbiased way to find eBay’s most popular items, because each watcher represents an individual who is interested!</p>
                <p><strong>Buyers, search for your favorite keywords or browse your preferred categories to find eBay’s best deals and most captivating items.</strong></p>
                <p>You will find fascinating, one-of-a-kind items, as well as eBay’s most popular deals, in our “Most Watched” searches.  Easily add additional filters or sort by watchers, bids, or price to find the right one for you!</p>
                <p>Want to negotiate with a motivated seller?  We make it easy to search for sellers accepting offers on items they listed long ago.  Find an interesting piece, check its purchase history and seller feedback, and make an aggressive offer.</p>
                <p><strong>Sellers, find popular products with lots of interest and sales to add to your inventory.</strong></p>
                <p>Explore your verticals and see the most popular products by watch count.  Which ones would you consider carrying?  Use keywords and pricing filters to drill down.  Easily check the purchase history to find the items with the best sales.  Filter by seller to see your competitors’ most watched products.  Identify listings where you are undercharging and can raise your rates.</p>
                <p><strong><i>Want to search eBay’s sold listings?  Simply slide the toggle above to “Search Sold.”</i></strong></p>
                <p><i>Buyers, if you are considering making an offer, bid, or purchase, <a href="mw2?status=sold&sortOrder=bestmatch">search eBay sold prices</a> to learn what similar items have sold for recently.</i></p>
                <p><i>Sellers, search eBay’s sold listings to find new niches and products, research hot products and validate pricing before sourcing new inventory, and dig deeper into market trends.</i></p>
            </div>
            </DisplayBox>
          </template>
          <DisplayBox v-if="mostWatchedRequest.status === 'sold'" title="Search Inspirations: eBay's Most Popular Sold Listings" :isHeader="mostWatchedResponse === null">
            <WhatsHotSold></WhatsHotSold>
          </DisplayBox>
          <DisplayBox v-else title="Search Inspirations: eBay's Most Popular Live Listings" :isHeader="mostWatchedResponse === null">
            <WhatsHotLive></WhatsHotLive>
          </DisplayBox>
        </template>
        <DisplayBox v-if="errorMsg !== ''">
          <div class="row my-3 text-center">
            <div class="col-12">
              <span class="warning">{{ errorMsg }}</span>
            </div>
          </div>
        </DisplayBox>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { 
  PropType,
  defineComponent
} from 'vue';

import {
  getEndpoint
} from '@/utils/auth';
import {deepCopy} from '@/utils/copy';
import {
    encodeMostWatchedRequest,
    Category,
    EbaySite,
    ListingTypeAll,
    ListingTypeAuction,
    ListingTypeBestOffer,
    ListingTypeFixedPrice,
    MostWatchedRequest,
    MostWatchedResponse,
    SortByAllWatchersDesc,
    SortByAllBidsAsc,
    SortByAllBidsDesc,
    SortByAllBestMatch,
    SortByAllPriceAsc,
    SortByAllPriceDesc,
    SortByAllListDateAsc,
    SortByAllEndDateAsc,
    SortByWatchers,
    SortByBestMatch,
    SortByBids,
    SortByEndDate,
    SortByListDate,
    SortByPrice,
    SortOrderAscending,
    SortOrderDescending,
    StatusLive,
    SubcategoryTree,
    ebaySiteUS,
    ebaySiteUK,
    ebaySiteCA,
    ebaySiteAU,
    ebaySiteIE,
    ebaySiteDE,
    ebaySiteFR,
    ebaySiteES,
    ebaySiteIT,
    ebaySiteNL,
    ebaySiteBENL,
    ebaySiteBEFR,
    ebaySiteAT,
    ebaySiteCH,
    ebaySiteMY,
    ebaySiteIN,
    ebaySiteSG,
    ebaySitePH
} from '@/types';
import {
  createFullRedirectURL
} from '@/utils/redirector';

import TopCategories from '@/components/TopCategories.vue';
import DisplayBox from '@/components/DisplayBox.vue';
import FormMostWatched from '@/components/FormMostWatched.vue';
import MostWatchedResults from '@/components/MostWatchedResults.vue';
import CountrySelector from '@/components/CountrySelector.vue';
import SearchPresets from '@/components/SearchPresets.vue';
import WhatsHotLive from '@/components/WhatsHotLive.vue';
import WhatsHotSold from '@/components/WhatsHotSold.vue';

export default defineComponent({
  name: 'MostWatchedView',
  head() {
    if (
      !('keywords' in this.$route.query || 'bkw' in this.$route.query || 'uqkw' in this.$route.query)
      && !(('categoryID' in this.$route.query || 'bcat' in this.$route.query || 'uqcat' in this.$route.query || 'bcts' in this.$route.query))
    ) {
      return;
    }

    return {
      title: this.title + ' by WatchCount',
      meta: [
        {
          name: 'description',
          content: this.description
        }
      ]
    }
  },
  components: {
    TopCategories,
    DisplayBox,
    FormMostWatched,
    MostWatchedResults,
    CountrySelector,
    SearchPresets,
    WhatsHotLive,
    WhatsHotSold
  },
  props: {
    country: {
      type: Object as PropType<EbaySite>,
      required: true
    },
    categories: {
      type: Object as PropType<Array<Category>>,
    }
  },
  created() {
    const keywords = this.$route.query.keywords || this.$route.query.bkw || this.$route.query.uqkw;
    const category = this.$route.query.categoryID || this.$route.query.bcat || this.$route.query.uqcat || this.$route.query.bcts;

    if (keywords || (category && category !== '0')) {
      this.submitSearch();
    }

    if (category && category !== '0') {
      this.getSubcategoryTree(category as string);
    }

    // Show the alert if they didn't get out of it already.
    if (localStorage.getItem('seenNotification-8-3-2024') === null) {
      this.showNotification = true;
    }
  },
  data() {
    return {
      localCountry: deepCopy(this.country) as EbaySite,
      mostWatchedRequest: this.initMostWatchedRequest(),
      currMostWatchedRequest: this.initMostWatchedRequest(),
      mostWatchedLoading: false as boolean,
      mostWatchedResponse: null as MostWatchedResponse | null,
      subcategoryTree: undefined as SubcategoryTree | undefined,
      statusLive: StatusLive,
      errorMsg: '',
      showNotification: false,
      createFullRedirectURL: createFullRedirectURL
    }
  },
  methods: {
    initMostWatchedRequest() {
      let legacyWatchCountListingType = '';
      if (this.$route.query.csbin === 'auc') {
        legacyWatchCountListingType = ListingTypeAuction.id;
      } else if (this.$route.query.csbin === 'all') {
        legacyWatchCountListingType = ListingTypeAll.id;
      } else if (this.$route.query.csbin === 'boa') {
        legacyWatchCountListingType = ListingTypeFixedPrice.id;
      }

      let legacyWatchCountSortBy = '';
      let legacyWatchCountSortOrder = '';
      if (this.$route.query.cssrt === 'mw') {
        legacyWatchCountSortBy = SortByWatchers.id;
        legacyWatchCountSortOrder = SortOrderDescending.id;
      } else if (this.$route.query.cssrt === 'ts') {
        legacyWatchCountSortBy = SortByEndDate.id;
        legacyWatchCountSortOrder = SortOrderAscending.id;
      } else if (this.$route.query.cssrt === 'ph') {
        legacyWatchCountSortBy = SortByPrice.id;
        legacyWatchCountSortOrder = SortOrderDescending.id;
      } else if (this.$route.query.cssrt === 'pl') {
        legacyWatchCountSortBy = SortByPrice.id;
        legacyWatchCountSortOrder = SortOrderAscending.id;
      } else if (this.$route.query.cssrt === 'bm') {
        legacyWatchCountSortBy = SortByBestMatch.id;
        legacyWatchCountSortOrder = SortOrderDescending.id;
      }

      let legacyWatchCountCountry = '';
      if (this.$route.query.cc === 'AT') {
        legacyWatchCountCountry = ebaySiteAT.id;
      } else if (this.$route.query.cc === 'AU') {
        legacyWatchCountCountry = ebaySiteAU.id;
      } else if (this.$route.query.cc === 'BENL') {
        legacyWatchCountCountry = ebaySiteBENL.id;
      } else if (this.$route.query.cc === 'CA') {
        legacyWatchCountCountry = ebaySiteCA.id;
      } else if (this.$route.query.cc === 'CH') {
        legacyWatchCountCountry = ebaySiteCH.id;
      } else if (this.$route.query.cc === 'DE') {
        legacyWatchCountCountry = ebaySiteDE.id;
      } else if (this.$route.query.cc === 'ES') {
        legacyWatchCountCountry = ebaySiteES.id;
      } else if (this.$route.query.cc === 'FR') {
        legacyWatchCountCountry = ebaySiteFR.id;
      } else if (this.$route.query.cc === 'IE') {
        legacyWatchCountCountry = ebaySiteIE.id;
      } else if (this.$route.query.cc === 'IN') {
        legacyWatchCountCountry = ebaySiteIN.id;
      } else if (this.$route.query.cc === 'IT') {
        legacyWatchCountCountry = ebaySiteIT.id;
      } else if (this.$route.query.cc === 'MY') {
        legacyWatchCountCountry = ebaySiteMY.id;
      } else if (this.$route.query.cc === 'NL') {
        legacyWatchCountCountry = ebaySiteNL.id;
      } else if (this.$route.query.cc === 'SG') {
        legacyWatchCountCountry = ebaySiteSG.id;
      } else if (this.$route.query.cc === 'UK') {
        legacyWatchCountCountry = ebaySiteUK.id;
      } else if (this.$route.query.cc === 'US') {
        legacyWatchCountCountry = ebaySiteUS.id;
      }

      let legacyWatchCountCategory = '';
      if (this.$route.query.bcat) {
        legacyWatchCountCategory = this.$route.query.bcat as string;
      } else if (this.$route.query.uqcat) {
        legacyWatchCountCategory = this.$route.query.uqcat as string;
      } else if (this.$route.query.bcts) {
        legacyWatchCountCategory = this.$route.query.bcts as string;
      }
      if (legacyWatchCountCategory === '0') {
        legacyWatchCountCategory = '';
      }

      let legacyWatchCountMaxBids = '';
      if (this.$route.query.hasOwnProperty('zero')) {
        legacyWatchCountMaxBids = '0';
      }

      // Support legacy status 'completed'
      let queryStatus = this.$route.query.status;
      if (queryStatus === 'completed') {
        queryStatus = 'sold';
      }

      return {
        keywords: this.$route.query.keywords || this.$route.query.bkw || this.$route.query.uqkw || '',
        categoryID: this.$route.query.categoryID || legacyWatchCountCategory || '',
        listingType: this.$route.query.listingType || legacyWatchCountListingType || ListingTypeAll.id,
        freeShippingOnly: this.$route.query.freeShippingOnly || (this.$route.query.bfso === '1') || false,
        descriptionSearch: this.$route.query.descriptionSearch || (this.$route.query.bds === '1') || false,
        seller: this.$route.query.seller || this.$route.query.bslr || '',
        minPrice: this.$route.query.minPrice || this.$route.query.bnp || null,
        maxPrice: this.$route.query.maxPrice || this.$route.query.bxp || null,
        minBids: this.$route.query.minBids || null,
        maxBids: this.$route.query.maxBids || legacyWatchCountMaxBids || null,
        sortBy: this.$route.query.sortBy || legacyWatchCountSortBy || SortByWatchers.id,
        sortOrder: this.$route.query.sortOrder || legacyWatchCountSortOrder || SortOrderDescending.id,
        status: queryStatus || StatusLive.id,
        site: this.$route.query.site || legacyWatchCountCountry || this.country.id,
        offset: this.$route.query.offset || 0
      } as MostWatchedRequest;
    },
    submitSearch() {
      this.currMostWatchedRequest = deepCopy(this.mostWatchedRequest);
      this.search(this.currMostWatchedRequest.offset);
    },
    search(offset: number) {
      this.mostWatchedResponse = null;
      this.mostWatchedLoading = true;

      this.currMostWatchedRequest.offset = offset;

      this.$router.push({
        path: this.$route.path,
        query: this.currMostWatchedRequest
      });

      this.subcategoryTree = undefined;

      this.errorMsg = '';

      fetch(
        getEndpoint() + '/mw2?' + encodeMostWatchedRequest(this.currMostWatchedRequest),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(
        async response => {
          if (response.ok) {
            if (this.currMostWatchedRequest.categoryID) {
              this.getSubcategoryTree(this.currMostWatchedRequest.categoryID);
            }
            this.mostWatchedResponse = await response.json();
          } else if (await response.text() === '100015') {
            this.errorMsg = 'This category is not supported for Sold Search.'; 
          }  else {
            this.errorMsg = 'An error occurred while searching. Please double-check the search inputs and make sure there is at least 1 keyword or category selected.'
          }
        }
      ).catch(
        error => {
          this.errorMsg = 'An error occurred while searching. Please double-check the search inputs and make sure there is at least 1 keyword or category selected.'
        }
      ).finally(
        () => {
          this.mostWatchedLoading = false;
        }
      );
    },
    getSubcategoryTree(categoryID: string) {      
      fetch(
        getEndpoint() + '/get_category_subtree/' + categoryID + '?site=' + this.country.id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(
        async response => {
          if (response.ok) {
            this.subcategoryTree = await response.json();
          }
        }
      )
    },
    closeNotification() {
      this.showNotification = false;
      localStorage.setItem('seenNotification-8-3-2024', 'true');
    },
    titleCase(s: string) {
      if (!s) {
        return '';
      }

      return s.replace(/\w\S*/g, text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
    }
  },
  computed: {
    keywordAndCategoryDescription: {
      get(): string {
        let hasCategory = 'categoryID' in this.$route.query || 'bcat' in this.$route.query || 'uqcat' in this.$route.query || 'bcts' in this.$route.query;

        let keywordAndCategoryDescription = '';
        if (this.$route.query.keywords && (hasCategory && this.subcategoryTree !== undefined && this.subcategoryTree !== null && this.subcategoryTree.category !== null && this.subcategoryTree.category !== undefined)) {
          keywordAndCategoryDescription = `${this.titleCase(this.$route.query.keywords as string)} in ${this.subcategoryTree.category.name}`;
        } else if (hasCategory && this.subcategoryTree !== undefined && this.subcategoryTree !== null && this.subcategoryTree.category !== null && this.subcategoryTree.category !== undefined) {
          keywordAndCategoryDescription = this.subcategoryTree.category.name;
        } else {
          keywordAndCategoryDescription = this.titleCase(this.$route.query.keywords as string);
        }

        return keywordAndCategoryDescription;
      },
      set() {

      }
    },
    title: {
      get(): string {
        let sortDescription = '';
        if (this.$route.query.sortBy === SortByWatchers.id) {
          sortDescription = SortByAllWatchersDesc.name;
        } else if (this.$route.query.sortBy === SortByBids.id && this.$route.query.sortOrder === SortOrderAscending.id) {
          sortDescription = SortByAllBidsAsc.name;
        } else if (this.$route.query.sortBy === SortByBids.id && this.$route.query.sortOrder === SortOrderDescending.id) {
          sortDescription = SortByAllBidsDesc.name;
        } else if (this.$route.query.sortBy === SortByBestMatch.id) {
          sortDescription = SortByAllBestMatch.name;
        } else if (this.$route.query.sortBy === SortByPrice.id && this.$route.query.sortOrder === SortOrderAscending.id) {
          sortDescription = SortByAllPriceAsc.name;
        } else if (this.$route.query.sortBy === SortByPrice.id && this.$route.query.sortOrder === SortOrderDescending.id) {
          sortDescription = SortByAllPriceDesc.name;
        } else if (this.$route.query.sortBy === SortByListDate.id) {
          sortDescription = SortByAllListDateAsc.name;
        } else if (this.$route.query.sortBy === SortByEndDate.id) {
          sortDescription = SortByAllEndDateAsc.name;
        }

        let titleSegments = [];
        titleSegments.push(this.keywordAndCategoryDescription);
        titleSegments.push(this.$route.query.status === 'live' ? '' : 'Sold');
        titleSegments.push(this.country.id === 'EBAY_US' ? 'eBay' : 'eBay ' + this.country.name);
        titleSegments.push(this.$route.query.listingType === 'auction' ? 'Auctions' : 'Listings');
        titleSegments.push(`Ranked by ${sortDescription}`);
        
        return titleSegments.join(' ');
      },
      set() {

      }
    },
    description: {
      get(): string {
        let descriptionSegments = [];
        if (this.$route.query.status === 'live') {
          descriptionSegments.push('Discover the most popular');
          descriptionSegments.push(this.keywordAndCategoryDescription);
          descriptionSegments.push(`items & auctions on ${this.country.id === 'EBAY_US' ? 'eBay.' : 'eBay ' + this.country.name + '.'}`);
          descriptionSegments.push(`Get the best deal on ${this.keywordAndCategoryDescription}`);
        } else {
          descriptionSegments.push(`${this.keywordAndCategoryDescription} eBay Sold Prices.`);
          descriptionSegments.push(`Buyers, what's it worth & how much can you bid? Sellers, what will it sell for & how long will it take?`);
        }

        return descriptionSegments.join(' ');
      },
      set() {

      }
    }
  },
  watch: {
    localCountry: {
      handler(val: EbaySite) {
        this.$emit('updateCountry', val);
      },
      deep: true
    }
  }
});
</script>

<style scoped>
  .pagination {
    color: #7a7a7a;
    font-size: 10pt;
    font-weight: normal;
    font-family: Tahoma,Verdana,Arial,Helvetica;
  }

  .link {
    color: #7812cc;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    font-family: Arial, Tahoma, Verdana;
  }

  .feedback-text {
        font-family: Tahoma,Verdana,Helvetica;
        font-size: 8pt;
    }

  .notif {
    border: 1px solid black;
  }

  .offcanvas {
    overflow: scroll !important;
  }

  .offcanvas-body {
    padding: 5px 0px;
  }
  
  .coupon {
  }

  .info {
    font-size: 16px;
    font-weight: 400;
  }
</style>
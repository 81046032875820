<template>
    <div class="display-box">
        <div class="display-box-header">
            <button v-if="menu" class="btn float-start d-block d-lg-none hamburger-button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button" aria-controls="offcanvas" @click="removeExtraFadeOffCanvas">
                <div class="hamburger"></div>
                <div class="hamburger"></div>
                <div class="hamburger"></div>
            </button>
            <h1 v-if="isHeader" class="title">{{ title }}</h1>
            <h2 v-else class="title">{{ title }}</h2>
        </div>
        <div class="display-box-body">
            <slot></slot>
        </div>
    </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'DisplayBox',
    props: {
        title: String,
        menu: Boolean,
        isHeader: Boolean
    },
    methods: {
        removeExtraFadeOffCanvas() {
            const myOffcanvas = document.getElementById('offcanvas');
            if (!myOffcanvas) {
                return;
            }
            myOffcanvas.addEventListener('shown.bs.offcanvas', event => {
                let fade = document.getElementsByClassName('offcanvas-backdrop fade show')
                for (let i = 0; i < fade.length; i++) {
                    while (fade.length > 1) {
                        fade[i].remove()
                    }
                }
            });
        }
    }
});
</script>

<style scoped>
    .display-box {
        margin-bottom: 20px;
    }
    .display-box-header {
        padding: 4px 7px 4px 9px;
        background: url('@/assets/fade-blue.jpg') repeat-x scroll top left;
        color: #444444;
        font-family: Tahoma,Trebuchet MS,Helvetica,Arial Black,Arial;
        font-size: 16pt;
        font-weight: bold;
        border-radius: 8px;
    }
    .display-box-body {
        padding: 9px 7px 3px 7px;
        background-color: #f9f9f9;
        font-family: Arial,Verdana,Tahoma,Helvetica;
        font-size: 10pt;
        font-weight: bold;
        color: #1a1a00;
        border-radius: 9px;
    }

    .hamburger-button {
        padding-top: 3px;
        padding-left: 3px;
    }

    .hamburger {
        width: 25px;
        height: 3px;
        background-color: black;
        margin: 4px 0;
    }

    .title {
        color: #444444;
        font-family: Tahoma,Trebuchet MS,Helvetica,Arial Black,Arial;
        font-size: 16pt;
        font-weight: bold;
        margin: 0px;
    }

    @media only screen and (max-width: 800px) {
        .display-box-header .title {
            font-size: 10pt;
        }

        .hamburger-button {
            padding-top: 0px;
        }

        .hamburger {
            height: 1px;
            width: 15px;
            margin: 3px 0px;
        }
    }

    @media only screen and (max-width: 500px) {
        .display-box-header .title {
            font-size: 8pt;
        }
    }
</style>
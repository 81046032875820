<template>
    <div class="row px-2">
        <div class="d-none d-lg-block col-auto pe-0">
            <DisplayBox title="Search">
                <SearchPresets></SearchPresets>
            </DisplayBox>
            <DisplayBox title="Categories">
                <TopCategories :categories="categories"></TopCategories>
            </DisplayBox>
        </div>
        <div class="offcanvas offcanvas-start d-block d-lg-none" id="offcanvas">
            <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <DisplayBox title="Search">
                <SearchPresets></SearchPresets>
                </DisplayBox>
                <DisplayBox title="Categories">
                <TopCategories :categories="categories"></TopCategories>
                </DisplayBox>
            </div>
        </div>
        <div class="col px-0">
            <div class="container">
                <DisplayBox title="WatchCount.com FAQ" :menu="true" :isHeader="true">
                    <div id="top" class="content">
                        <p class="title">Frequently Asked Questions (General FAQ)</p>

                        <p class="section-title">Basics</p>
                        <ul class="section-list">
                            <li class="section-link"><a href="#watchcount-definition">What is a Watch Count?</a></li>
                            <li class="section-link"><a href="#watchcount-importance">Why should I care about an item's Watch Count?</a></li>
                            <li class="section-link"><a href="#using-wcc">Why would I want to use this site?</a></li>
                        </ul>

                        <div class="section-footnote">
                            <hr>
                            <ul>
                                <li>
                                    <span>Try a new <a href="/mw2?listingType=all&status=live&sortBy=watchcount&sortOrder=desc&preset=mw" target="_blank">Most Watched search</a>.</span>
                                </li>
                            </ul>
                            <hr>
                        </div>

                        <p id="watch-count-definition" class="question-title">What is a Watch Count?</p>
                        <p class="question-text">Every eBay item (auction, Buy It Now, Store listing, Classified Ad, etc.) has a couple of "Add to Watch List / Watch This Item" links on the listing page that users can click to keep tabs on the item. The item is then listed in their My eBay console as a reminder for future reference.</p>
                        <p class="question-text">eBay shoppers use this to "bookmark" interesting items for a variety of reasons, including getting a reminder email from eBay shortly before the item's listing ends ("Watched item ending soon - don't forget to bid!").</p>
                        <p class="question-text">eBay sellers tend to use this feature to keep tabs on their competition (how much did this item ultimately sell for?) and as one of many ways to generally monitor the market.</p>
                        <p class="question-text">This total number of watchers of a certain eBay item is often called the "Watch Count".</p>
                        <table class="question-footnote-table">
                            <tr>
                                <td>
                                    <ul class="question-footnote">
                                        <li>
                                            Try a <a href="/mw2?listingType=all&status=live&sortBy=watchcount&sortOrder=desc&preset=mw" target="_blank">Most Watched search</a>.
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul class="question-footnote">
                                        <li>
                                            Jump to <a href="#top">the top</a>.
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </table>

                        <p id="watch-count-importance" class="question-title">Why should I care about an item's Watch Count?</p>
                        <p class="question-text">The Watch Count can be an informative metric to use when evaluating market demand. Seeing what items others have heavily (or lightly) flagged for watching can help both buyers and sellers alike make more informed decisions in their activities on eBay.</p>
                        <p class="question-text">For example, when comparing similar items on eBay, if one has a much greater Watch Count than the others, that may indicate a disparity worth investigating further (does it possess a more valuable feature? a more popular color? a more attractive listing? a better listing title?).</p>
                        <p class="question-text">Also, when combined with the current Bid Count, one can garnish some more insight into item popularity. For example, if an item is a few hours from ending, has only a few bids but a high Watch Count, there may be a greater potential for subsequent bidding or sniping to occur in the closing moments of the auction.</p>
                        <p class="question-text">And if you like cruising around eBay checking out miscellaneous listings, know that sometimes it can be fun to gaze at how many watchers some items accumulate.</p>
                        <table class="question-footnote-table">
                            <tr>
                                <td>
                                    <ul class="question-footnote">
                                        <li>
                                            Try a <a href="/mw2?listingType=all&status=live&sortBy=watchcount&sortOrder=desc&preset=mw" target="_blank">Most Watched search</a>.
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul class="question-footnote">
                                        <li>
                                            Jump to <a href="#top">the top</a>.
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </table>

                        <p id="using-wcc" class="question-title">Why would I want to use this site?</p>
                        <p class="question-text">As an eBay shopper, you may find it helpful to "peek in on" how many people have flagged an item as 'watched'. It can be an added factor when comparison shopping, even more so if you want to see how an item's Watch Count changes over time, or if, say, you're a collector who needs to be aware of competing interest in a target item.</p>
                        <p class="question-text">If you're a seller, you can use the results displayed by WatchCount.com to add another edge to your market research efforts. You're already watching important items; now you can see how many others are, at various points leading up to auction ending. And, naturally, high Watch Counts on an item may be a relative indication of consumer appeal, suggesting a hot item, attractive listing/photos, or intelligently selected title keywords. (See "<a href="#watch-count-importance">Why should I care about an item's Watch Count?</a>".)</p>
                        <p class="question-text">Even if you're not selling on eBay, if you are involved in ecommerce at all, you can use our site as a quick market research tool to see what's hot on eBay and what kinds of listings/ads amass the most number of active eyeballs – or the <a href="/mw2?listingType=auction&status=live&sortBy=bids&sortOrder=desc&preset=mb" target="_blank">most number of bids</a>.</p>
                        <table class="question-footnote-table">
                            <tr>
                                <td>
                                    <ul class="question-footnote">
                                        <li>
                                            Try a <a href="/mw2?listingType=all&status=live&sortBy=watchcount&sortOrder=desc&preset=mw" target="_blank">Most Watched search</a>.
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul class="question-footnote">
                                        <li>
                                            Jump to <a href="#top">the top</a>.
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </div>
                </DisplayBox>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { 
  PropType,
  defineComponent
} from 'vue';

import SearchPresets from '@/components/SearchPresets.vue';
import TopCategories from '@/components/TopCategories.vue';
import DisplayBox from '@/components/DisplayBox.vue';

import {
    EbaySite,
    Category
} from '@/types';

export default defineComponent({
    name: 'FaqView',
    components: {
        SearchPresets,
        TopCategories,
        DisplayBox
    },
    props: {
        country: {
        type: Object as PropType<EbaySite>,
        required: true
        },
        categories: {
        type: Object as PropType<Array<Category>>,
        }
    }
});
</script>

<style scoped>
.offcanvas {
    overflow: scroll !important;
}

.content {
    background-color: #f6f6ff;
}

.title {
    text-align: center;
    font-family: Arial,Tahoma,Verdana,Helvetica;
    font-size: 14pt;
    font-weight: bold;
    color: #00004d;
    line-height: 110%;
}
.section-title {
    font-family: Arial,Tahoma,Verdana,Helvetica;
    font-size: 12pt;
    font-weight: bold;
    color: #4d4d4d;
    line-height: 110%;
}
.section-list {
    list-style-image: url('@/assets/little-green-ball.gif');
}
.section-link a {
    color: #7812cc;
    text-decoration: none;
}
.section-footnote {

}
.section-footnote ul {
    list-style-image: url('@/assets/red-arrow.gif');
    list-style-position: outside;
    margin: 0px;
    padding: 0px 0px 0px 15px;
}
.section-footnote span {
    font-family: Verdana,Tahoma,Geneva;
    font-size: 8pt;
    font-weight: normal;
    color: #262626;
}
.section-footnote a {
    color: #7812cc;
    text-decoration: none;
}

.question-title {
    color: #111111;
    font-family: Arial,Verdana;
    font-size: 10pt;
    font-weight: bold;
}
.question-text {
    color: #111111;
    font-family: Arial,Verdana;
    font-size: 9pt;
    font-weight: normal;
    margin: 6px 17px 6px 32px;
}
.question-text a {
    color: #7812cc;
    text-decoration: none;
}
.question-footnote-table {
    margin: 0 auto !important;
    margin-top: 10px !important;
    margin-bottom: 40px !important;
    border-top: 1px solid 1px solid #00e6e6;
    border-bottom: 1px solid #00e6e6;
    background: #80ffff;
    width: 70%;
}
.question-footnote-table td {
    padding: 4px 8px 1px 8px;
}
.question-footnote {
    list-style-image: url('@/assets/red-arrow.gif');
    list-style-position: inside;
    margin: 0px;
    padding: 0px 0px 0px 15px;
}
.question-footnote li {
    margin: 0px;
    padding: 0px 0px 4px 0px;
    text-indent: 0px;
    font-family: Verdana,Tahoma,Geneva;
    font-size: 8pt;
    font-weight: normal;
    color: #262626;
}
.question-footnote a {
    color: #7812cc;
    text-decoration: none;
}
</style>
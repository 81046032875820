import { createApp } from 'vue'
import { VueHeadMixin, createHead } from '@unhead/vue';
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@/styles/styles.css';

const app = createApp(App)
const head = createHead()

app.use(router)
app.mixin(VueHeadMixin)

app.mount('#app')

<template>
    <div class="item">
        <!-- Everything until More Details -->
        <div class="row">
            <!-- Image column -->
            <div class="col-12 text-center col-lg-auto">
                <div class="image-container">
                    <a :href="itemRedirectURL" target="_blank">
                        <img class="image" :src="item.image" :alt="item.title">
                    </a>
                </div>
            </div>
            <!-- First 4 quardants -->
            <div class="col-12 col-lg">
                <!-- Blue and pink quadrants -->
                <div class="row">
                    <!-- Blue quadrant -->
                    <div class="col-12 col-lg">
                        <div v-if="request.status === 'live'" class="row">
                            <div class="col text-start">
                                <span class="watch-count">Watch Count: {{ item.watchCount }} eBay <span v-if="item.watchCount === 1">watcher</span><span v-else>watchers</span></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-start">
                                <a class="item-title" :href="itemRedirectURL" target="_blank">{{ item.title }}</a>
                            </div>
                        </div>
                        <div v-if="price" class="row">
                            <div class="col">
                                <div v-if="item.discountType !== 'MAP'" class="price">{{ price }}</div>
                                <div v-else><a :href="itemRedirectURL" class="small-text">See price</a></div>
                                <div v-if="originalPrice && item.discountType === 'STP'">
                                    <span class="stp">{{ originalPrice }}</span> <span v-if="percentageOff" class="red">({{ percentageOff }})</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="item.listingType === 'Auction'" class="row">
                            <div v-if="item.bids !== undefined && item.bids !== null" class="col">
                                Bid Count: {{ item.bids }} <span v-if="item.uniqueBidders !== undefined && uniqueBidders !== null">(Unique Bidders: {{ item.uniqueBidders }})</span>
                            </div>
                        </div>
                        <div v-if="startTime" class="row">
                            <div class="col text-start">
                                Start Time: {{ startTime }}
                            </div>
                        </div>
                        <div v-if="endTime" class="row">
                            <div class="col text-start">
                                {{ endTime }}
                            </div>
                        </div>
                    </div>
                    <!-- Pink quadrant -->
                    <div class="col-12 col-lg-auto">
                        <div class="row">
                            <div class="col text-lg-end">
                                <span class="small-text">{{ item.id }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-lg-end">
                                <span class="listing-type">{{ item.listingType }}</span>
                            </div>
                        </div>
                        <div v-if="item.status !== undefined && item.status !== null" class="row">
                            <div class="col text-lg-end">
                                <span :class="{'green': item.status.includes('Active'), 'red': !item.status.includes('Active')}">{{ item.status }}</span>
                            </div>
                        </div>
                        <div v-if="item.bestOfferEnabled" class="row">
                            <div class="col text-lg-end">
                                <span class="special">Best Offer Enabled</span>
                            </div>
                        </div>
                        <div v-if="item.freeShipping" class="row">
                            <div class="col text-lg-end">
                                <span class="special">Free Shipping</span>
                            </div>
                        </div>
                        <div v-if="item.marketplace" class="row">
                            <div class="col text-lg-end">
                                Listed on: {{ item.marketplace }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Yellow and orange quadrants -->
                <div class="row my-1">
                    <!-- Yellow quadrant -->
                    <div class="col-12 col-lg">
                        <div class="row my-3">
                            <div class="col">
                                <a class="small-text small-link" :href="sellerRedirectURL" target="_blank">{{ item.seller }}</a> ({{ item.sellerFeedbackScore.toLocaleString('en-US') }}) {{ item.sellerFeedbackPercentage }}%
                            </div>
                        </div>
                    </div>
                    <!-- Orange quadrant -->
                    <div class="col-12 col-lg">
                        <div v-if="false && item.lastSoldFor && item.currency" class="row">
                            <div class="col text-lg-end">
                                Last sold for {{ item.lastSoldFor.toLocaleString('en-us', {style: 'currency', currency: item.currency}) }} on {{ lastSoldDate }}
                            </div>
                        </div>
                        <div v-if="item.listingType === 'Fixed Price'" class="row">
                            <div class="col text-lg-end">
                                [<a :href="historyRedirectURL" target="_blank">See purchase history on eBay</a>]
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg">
                        <div v-if="item.listingType === 'Fixed Price' && item.quantitySold !== undefined && item.quantitySold !== null" class="row">
                            <div class="col">
                                Total Sold: {{ item.quantitySold.toLocaleString('en-us') }}
                            </div>
                        </div>
                        <div v-if="item.listingType === 'Fixed Price' && item.quantityAvailable !== undefined && item.quantityAvailable !== null" class="row">
                            <div class="col">
                                Quantity Available: <span v-if="typeof item.quantityAvailable === 'number'">{{ item.quantityAvailable.toLocaleString('en-us') }}</span><span v-else>{{ item.quantityAvailable }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <!-- More details button -->
        <div class="row">
            <div class="col text-center">
                <a v-if="!showDetails" href="#" class="small-text" @click.prevent="showDetails = true">More Details ▼</a>
                <a v-else href="#" class="small-text" @click.prevent="showDetails = false">Less Details ▲</a>
            </div>
        </div>
        <!-- Blue quadrant -->
        <div v-if="showDetails" class="row">
            <div class="col">
                <div v-if="item.subtitle" class="row">
                    <div class="col">
                        Subtitle: {{ item.subtitle }}
                    </div>
                </div>
                <div v-if="item.primaryCategoryTree" class="row">
                    <div class="col">
                        Primary Category: {{ item.primaryCategoryTree }} [<a class="small-text" :href="mostWatchedPrimaryCategoryLink" target="_blank">most watched</a>] [<a class="small-text" :href="mostBidsPrimaryCategoryLink" target="_blank">most bids</a>]
                    </div>
                </div>
                <div v-if="item.secondaryCategoryTree" class="row">
                    <div class="col">
                        Secondary Category: {{ item.secondaryCategoryTree }} [<a class="small-text" :href="mostWatchedSecondaryCategoryLink" target="_blank">most watched</a>] [<a class="small-text" :href="mostBidsSecondaryCategoryLink" target="_blank">most bids</a>]
                    </div>
                </div>
                <div v-if="item.location" class="row">
                    <div class="col">
                        Location: {{ item.location }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {
    PropType,
    defineComponent
} from 'vue';

import {
    MostWatchedRequest,
    MostWatchedResponse,
    MostWatchedResponseItem,
    encodeMostWatchedRequest,
    ebaySiteMap
} from '@/types';
import {
    getCountry
} from '@/utils/country';
import {
    createRedirectURL,
    createFullRedirectURL
} from '@/utils/redirector';

export default defineComponent({
    name: 'MostWatchedResultsItem',
    props: {
        request: {
            type: Object as PropType<MostWatchedRequest>,
            required: true
        },
        results: {
            type: Object as PropType<MostWatchedResponse>,
            required: true
        },
        item: {
            type: Object as PropType<MostWatchedResponseItem>,
            required: true
        }
    },
    data() {
        return {
            'country': getCountry(),
            'itemRedirectURL': createRedirectURL(getCountry().site, this.item.id, '', this.request.status, encodeMostWatchedRequest(this.request), this.results.requestID),
            'sellerRedirectURL': createRedirectURL(getCountry().site, '', this.item.seller, this.request.status, encodeMostWatchedRequest(this.request), this.results.requestID),
            'historyRedirectURL': createFullRedirectURL('https://' + getCountry().site + '/bin/purchaseHistory/' + this.item.id, encodeMostWatchedRequest(this.request), this.results.requestID),
            'showDetails': false,
            'mostWatchedPrimaryCategoryLink': `/mw2?categoryID=${this.item.primaryCategory}&sortBy=watchcount&sortOrder=desc&site=${this.request.site}`,
            'mostBidsPrimaryCategoryLink': `/mw2?categoryID=${this.item.primaryCategory}&listingType=auction&sortBy=bids&sortOrder=desc&site=${this.request.site}`,
            'mostWatchedSecondaryCategoryLink': `/mw2?categoryID=${this.item.secondaryCategory}&sortBy=watchcount&sortOrder=desc&site=${this.request.site}`,
            'mostBidsSecondaryCategoryLink': `/mw2?categoryID=${this.item.secondaryCategory}&listingType=auction&sortBy=bids&sortOrder=desc&site=${this.request.site}`
        }
    },
    methods: {
        openItemDetailsWindow(itemID: string) {
            window.open('/itm?itemID=' + itemID + '&site=' + this.country.id, 'Item ' + itemID, 'width=1366, height=768');
        }
    },
    computed: {
        timeLeft: {
            get(): string {
                if (!this.item.endTime) {
                    return '';
                }

                // Get current date and time
                const now = new Date();

                // Get the difference in milliseconds
                const differenceInMs = new Date(this.item.endTime).getTime() - now.getTime();

                // Calculate days, hours, minutes, and seconds
                const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
                const hours = Math.floor((differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);

                // Format the output string
                return `${days}d ${hours}h ${minutes}m ${seconds}s`;
            },
            set() {
                
            }
        },
        bids: {
            get(): string {
                if (this.item.bids === undefined || this.item.bids === null) {
                    return '';
                }

                var result = this.item.bids + ' bid';
                if (this.item.bids !== 1) {
                    result += 's';
                }

                return result;
            },
            set() {
                
            }
        },
        lastSoldDate: {
            get(): string {
                if (!this.item.lastSoldDate) {
                    return '';
                }

                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                // Format the date part.
                const lastSoldDate = new Date(this.item.lastSoldDate);
                const dayName = days[lastSoldDate.getUTCDay()];
                const day = String(lastSoldDate.getUTCDate()).padStart(2, '0');
                const monthName = months[lastSoldDate.getUTCMonth()];
                const year = lastSoldDate.getUTCFullYear().toString().slice(-2); // Get last two digits of year
                const hours = String(lastSoldDate.getUTCHours()).padStart(2, '0');
                const minutes = String(lastSoldDate.getUTCMinutes()).padStart(2, '0');
                const seconds = String(lastSoldDate.getUTCSeconds()).padStart(2, '0');
                const formattedDate = `${dayName}, ${day}-${monthName}-${year} ${hours}:${minutes}:${seconds}`;

                return `${formattedDate} UTC`;
            },
            set() {
                
            }
        },
        startTime: {
            get(): string {
                if (!this.item.startTime) {
                    return '';
                }

                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                // Format the date part.
                const startTime = new Date(this.item.startTime);
                const dayName = days[startTime.getUTCDay()];
                const day = String(startTime.getUTCDate()).padStart(2, '0');
                const monthName = months[startTime.getUTCMonth()];
                const year = startTime.getUTCFullYear().toString().slice(-2); // Get last two digits of year
                const hours = String(startTime.getUTCHours()).padStart(2, '0');
                const minutes = String(startTime.getUTCMinutes()).padStart(2, '0');
                const seconds = String(startTime.getUTCSeconds()).padStart(2, '0');
                const formattedDate = `${dayName}, ${day}-${monthName}-${year} ${hours}:${minutes}:${seconds}`;

                // Get the difference between the end time and now.
                const now = new Date();
                const diffMs = now.getTime() - startTime.getTime();
                const diffSec = Math.floor(diffMs / 1000);
                const diffMin = Math.floor(diffSec / 60);
                const diffHr = Math.floor(diffMin / 60);
                const diffDay = Math.floor(diffHr / 24);
                const diffMonth = Math.floor(diffDay / 30);
                const diffYear = Math.floor(diffMonth / 12);
                let timeAgo = '';
                if (diffYear > 0) {
                    timeAgo = `${diffYear} year${diffYear > 1 ? 's' : ''} ago`;
                } else if (diffMonth > 0) {
                    timeAgo = `${diffMonth} month${diffMonth > 1 ? 's' : ''} ago`;
                } else if (diffDay > 0) {
                    timeAgo = `${diffDay} day${diffDay > 1 ? 's' : ''} ago`;
                } else if (diffHr > 0) {
                    timeAgo = `${diffHr} hour${diffHr > 1 ? 's' : ''} ago`;
                } else if (diffMin > 0) {
                    timeAgo = `${diffMin} minute${diffMin > 1 ? 's' : ''} ago`;
                } else {
                    timeAgo = `${diffSec} second${diffSec > 1 ? 's' : ''} ago`;
                }

                return `${formattedDate} UTC (${timeAgo})`;
            },
            set() {

            }
        },
        endTime: {
            get(): string {
                if (!this.item.endTime) {
                    return '';
                }

                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                // Format the date part.
                const endTime = new Date(this.item.endTime);
                const dayName = days[endTime.getUTCDay()];
                const day = String(endTime.getUTCDate()).padStart(2, '0');
                const monthName = months[endTime.getUTCMonth()];
                const year = endTime.getUTCFullYear().toString().slice(-2); // Get last two digits of year
                const hours = String(endTime.getUTCHours()).padStart(2, '0');
                const minutes = String(endTime.getUTCMinutes()).padStart(2, '0');
                const seconds = String(endTime.getUTCSeconds()).padStart(2, '0');
                const formattedDate = `${dayName}, ${day}-${monthName}-${year} ${hours}:${minutes}:${seconds}`;

                // Get the difference between the end time and now.
                const now = new Date();
                const diffMs = now.getTime() - endTime.getTime();
                const diffSec = Math.floor(Math.abs(diffMs) / 1000);
                const diffMin = Math.floor(Math.abs(diffSec) / 60);
                const diffHr = Math.floor(Math.abs(diffMin) / 60);
                const diffDay = Math.floor(Math.abs(diffHr) / 24);
                const diffMonth = Math.floor(Math.abs(diffDay) / 30);
                const diffYear = Math.floor(Math.abs(diffMonth) / 12);
                let timeAgo = '';
                if (diffYear > 0) {
                    timeAgo = `${diffYear} year${diffYear > 1 ? 's' : ''}`;
                } else if (diffMonth > 0) {
                    timeAgo = `${diffMonth} month${diffMonth > 1 ? 's' : ''}`;
                } else if (diffDay > 0) {
                    timeAgo = `${diffDay} day${diffDay > 1 ? 's' : ''}`;
                } else if (diffHr > 0) {
                    timeAgo = `${diffHr} hour${diffHr > 1 ? 's' : ''}`;
                } else if (diffMin > 0) {
                    timeAgo = `${diffMin} minute${diffMin > 1 ? 's' : ''}`;
                } else {
                    timeAgo = `${diffSec} second${diffSec > 1 ? 's' : ''}`;
                }

                // Make the prefix.
                let prefix = 'End Time:';
                if (this.item.listingType === 'Auction') {
                    if (diffMs < 0) {
                        prefix = 'Auction ends';
                    } else {
                        prefix = 'Auction ended';
                    }
                }

                // Make the direction.
                let direction = 'ago';
                if (diffMs < 0) {
                    direction = 'from now';
                }

                return `${prefix} ${formattedDate} UTC (${timeAgo} ${direction})`;
            },
            set() {

            }
        },
        price: {
            get() {
                if (this.item.price === undefined || this.item.price === null) {
                    return '';
                }

                let prefix = '';
                if (this.item.status === 'Sold') {
                    prefix = 'End Price:'
                } else {
                    prefix = 'Current Price:'
                }

                let price = '';
                if (this.item.price[0] === this.item.price[this.item.price.length-1]) {
                    price = this.item.price[0].toLocaleString('en-us', {style: 'currency', currency: this.item.currency});
                } else {
                    price = this.item.price[0].toLocaleString('en-US', {style: 'currency', currency: this.item.currency}) + ' to ' + this.item.price[this.item.price.length-1].toLocaleString('en-US', {style: 'currency', currency: this.item.currency});
                }

                return `${prefix} ${price}`;
            },
            set() {

            }
        },
        originalPrice: {
            get() {
                if (this.item.originalPrice === undefined || this.item.originalPrice === null) {
                    return '';
                }

                let prefix = 'List Price:';

                let price = '';
                if (this.item.originalPrice[0] === this.item.originalPrice[this.item.originalPrice.length-1]) {
                    price = this.item.originalPrice[0].toLocaleString('en-us', {style: 'currency', currency: this.item.currency});
                } else {
                    price = this.item.originalPrice[0].toLocaleString('en-US', {style: 'currency', currency: this.item.currency}) + ' to ' + this.item.originalPrice[this.item.originalPrice.length-1].toLocaleString('en-US', {style: 'currency', currency: this.item.currency});
                }

                return `${prefix} ${price}`;
            },
            set() {

            }
        },
        percentageOff: {
            get() {
                if (this.item.price === undefined || this.item.price === null) {
                    return '';
                }

                if (this.item.originalPrice === undefined || this.item.originalPrice === null) {
                    return '';
                }

                let percent = '';

                const percentageOff = Math.round(((this.item.originalPrice[0] - this.item.price[0]) / this.item.originalPrice[0]) * 100);

                percent = `${percentageOff}% off`;

                return percent;
            },
            set() {

            }
        }
    }
});
</script>

<style scoped>
    .item {
        background: url('@/assets/fade-blue-2.png') repeat-x scroll top left;
        padding: 15px;
        background-size: contain;
    }

    .image-container {
        width: 140px;
        margin: 0 auto;
    }
    .image {
        border: 1px solid #a6a6a6;
        max-height: 140px;
        max-width: 140px;
        margin: auto 0;
    }

    .watch-count {
        color: #262626;
        font-family: Arial,Helvetica,Verdana;
        background-color: #ffff80;
        border-radius: 5px 0px 0px 5px;
        font-size: 11pt;
        font-weight: bold;
    }

    .item-title {
        color: #7812cc;
        text-decoration: none;
        font-family: Tahoma,Arial,Verdana,Helvetica;
        font-weight: bold;
        font-size: 11pt;
    }
    .item-title:hover {
        color: 3002699;
        text-decoration: underline;
    }

    .country {
        color: #9a9a9a;
        font-weight: normal;
        font-family: Tahoma,Arial,Verdana;
        font-size: 8pt;
    }

    .listing-type {
        color: #0066cc;
        font-weight: bold;
        font-family: Verdana,Arial,Helvetica;
        font-size: 10.6667px;
    }

    .price {
        color: #111111;
        font-weight: bold;
        font-family: Verdana,Arial,Helvetica;
        font-size: 10pt;
    }
    .currency {
        color: #9a9a9a;
        font-weight: normal;
        font-family: Tahoma,Arial,Verdana;
        font-size: 8pt;
    }
    .small-text {
        color: #7812cc;
        text-decoration: none;
        font-family: Verdana,Arial,Helvetica;
        font-weight: bold;
        font-size: 8pt;
    }
    .small-link:hover {
        color: 3002699;
        text-decoration: underline;
    }
    .special {
        color: #00bfff;
        font-family: Verdana,Arial,Helvetica;
        font-size: 8pt;
    }

    .green {
        color: green;
        font-weight: bold;
    }

    .red {
        color: #bf2828;
        font-family: Verdana,Arial,Helvetica;
        font-weight: bold;
        font-size: 10pt;
    }

    .stp {
        text-decoration:line-through;
    }
</style>
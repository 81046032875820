<template>
    <div class="container">
        <a class="whats-hot-link" href="/?keywords=ps5">ps5</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Xbox">Xbox</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=laptop&categoryID=175672">laptop</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=gaming+pc">gaming pc</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=9355">Cell Phones</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Samsung+Galaxy&categoryID=15032">Samsung Galaxy</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=iPhone&categoryID=9355">iPhone</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Apple+Watch">Apple Watch</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=iPad&categoryID=58058">Apple iPads</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Roomba+iRobot">Roomba iRobot</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Air+Fryer">Air Fryer</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=29585">Men's Fragrances</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Rolex">Rolex</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Gucci">Gucci</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Diamond&categoryID=4196">Diamond Jewelry</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Tiffany+%26+Co.&categoryID=4196">Tiffany Jewelry</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=morgan+dollar&categoryID=11116">Morgan Dollar</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=American+Silver+Eagle">American Silver Eagle</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Gold+Bar&categoryID=39482">Gold Bar</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Silver+Bar&categoryID=39482">Silver Bar</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=20081">Antiques</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Movie+Posters&categoryID=550">Movie Posters</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Kindle&categoryID=171485">Kindle</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=267">Books</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Drone&categoryID=625">Drones</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Knife&categoryID=1">Knives</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Disney&categoryID=1">Disney</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=63">Comic Books</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Batman&categoryID=1">Batman</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Bose">Bose Audio</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Floor+Mats&categoryID=6000">Car Floor Mats</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=196">Move Memorabilia</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Signed&categoryID=166704">Signed TV Scripts</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=John+Deere+lawn+tractor">John Deere lawn tractor</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=DeWalt&categoryID=631">DeWalt Tools</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Taylor+Swift">Taylor Swift</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Elvis+Presley">Elvis Presley</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=3858">Guitars</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=American+Girl&categoryID=238">American Girl Outfits</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Michael+Jordan&categoryID=64482">Michael Jordan</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Shohei+Ohtani&categoryID=64482">Shohei Ohtani</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Mike+Tyson">Mike Tyson</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Mickey+Mantle&categoryID=212">Mickey Mantle</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/??keywords=Ronald+Acuna+Jr&categoryID=212">Ronald Acuna, Jr.</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=PSA&categoryID=212">PSA Card</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=PSA+DNA&categoryID=51">PSA/DNA autographs</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Pokemon&categoryID=220">Pokemon</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Lego&categoryID=220">Lego</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=YUGIOH&categoryID=2536">Yugioh</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=(Magic,MTG)&categoryID=2536">Magic: The Gathering</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Titanic">Titanic</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Cat+Tree&categoryID=1281">Cat Trees</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Gymboree&categoryID=171146">Gymboree</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=vintage">Vintage</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Nike">Nike</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Lululemon">Lululemon</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Abercrombie+%26+Fitch">Abercrombie & Fitch</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=yin+yang">Ying & Yang</a>&nbsp;&nbsp;
    </div>
</template>

<script lang="ts">
import { 
    PropType,
    defineComponent
} from "vue";

export default defineComponent({
    name: 'WhatsHotLive'
});
</script>

<style>
.whats-hot-link {
    margin: 0px 10px;
    color: #0033CC;
    text-decoration: none;
    font-family: Microsoft Sans Serif,Arial,Verdana,Tahoma,Helvetica;
    font-size: 14px !important;
    font-weight: normal;
    display: contents;
}
.whats-hot-link:hover {
    color: #002699;
    text-decoration: underline;
    font-weight: bold;
}
</style>
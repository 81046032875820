cat trees<template>
    <div class="container">
        <a class="whats-hot-link" href="/?keywords=ps5&sortBy=price&sortOrder=desc&status=sold">ps5</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Xbox&sortBy=price&sortOrder=desc&status=sold">Xbox</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=laptop&categoryID=175672&sortBy=price&sortOrder=desc&status=sold">laptop</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=gaming+pc&sortBy=price&sortOrder=desc&status=sold">gaming pc</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=9355&sortBy=price&sortOrder=desc&status=sold">Cell Phones</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Samsung+Galaxy&categoryID=15032&sortBy=price&sortOrder=desc&status=sold">Samsung Galaxy</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=iPhone&categoryID=9355&sortBy=price&sortOrder=desc&status=sold">iPhone</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Apple+Watch&sortBy=price&sortOrder=desc&status=sold">Apple Watch</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=iPad&categoryID=58058&sortBy=price&sortOrder=desc&status=sold">Apple iPads</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Roomba+iRobot&sortBy=price&sortOrder=desc&status=sold">Roomba iRobot</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Air+Fryer&sortBy=price&sortOrder=desc&status=sold">Air Fryer</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=29585&sortBy=price&sortOrder=desc&status=sold">Men's Fragrances</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Rolex&sortBy=price&sortOrder=desc&status=sold">Rolex</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Gucci&sortBy=price&sortOrder=desc&status=sold">Gucci</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Diamond&categoryID=4196&sortBy=price&sortOrder=desc&status=sold">Diamond Jewelry</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Tiffany+%26+Co.&categoryID=4196&sortBy=price&sortOrder=desc&status=sold">Tiffany Jewelry</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=morgan+dollar&categoryID=11116&sortBy=price&sortOrder=desc&status=sold">Morgan Dollar</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=American+Silver+Eagle&sortBy=price&sortOrder=desc&status=sold">American Silver Eagle</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Gold+Bar&categoryID=39482&sortBy=price&sortOrder=desc&status=sold">Gold Bar</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Silver+Bar&categoryID=39482&sortBy=price&sortOrder=desc&status=sold">Silver Bar</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=20081&sortBy=price&sortOrder=desc&status=sold">Antiques</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Movie+Posters&categoryID=550&sortBy=price&sortOrder=desc&status=sold">Movie Posters</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Drone&categoryID=625&sortBy=price&sortOrder=desc&status=sold">Drones</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Knife&categoryID=1&sortBy=price&sortOrder=desc&status=sold">Knives</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Disney&categoryID=1&sortBy=price&sortOrder=desc&status=sold">Disney</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=63&sortBy=price&sortOrder=desc&status=sold">Comic Books</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Batman&categoryID=1&sortBy=price&sortOrder=desc&status=sold">Batman</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Bose&sortBy=price&sortOrder=desc&categoryID=14990&status=sold">Bose Audio</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Floor+Mats&categoryID=6000&sortBy=price&sortOrder=desc&status=sold">Car Floor Mats</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=196&sortBy=price&sortOrder=desc&status=sold">Move Memorabilia</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Signed&categoryID=166704&sortBy=price&sortOrder=desc&status=sold">Signed TV Scripts</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Taylor+Swift&sortBy=price&sortOrder=desc&status=sold">Taylor Swift</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Elvis+Presley&sortBy=price&sortOrder=desc&status=sold">Elvis Presley</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?categoryID=3858&sortBy=price&sortOrder=desc&status=sold">Guitars</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=American+Girl&categoryID=238&sortBy=price&sortOrder=desc&status=sold">American Girl Outfits</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Michael+Jordan&categoryID=64482&sortBy=price&sortOrder=desc&status=sold">Michael Jordan</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Shohei+Ohtani&categoryID=64482&sortBy=price&sortOrder=desc&status=sold">Shohei Ohtani</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Mike+Tyson&sortBy=price&sortOrder=desc&status=sold">Mike Tyson</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Mickey+Mantle&categoryID=212&sortBy=price&sortOrder=desc&status=sold">Mickey Mantle</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/??keywords=Ronald+Acuna+Jr&categoryID=212&sortBy=price&sortOrder=desc&status=sold">Ronald Acuna, Jr.</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=PSA&categoryID=212&sortBy=price&sortOrder=desc&status=sold">PSA Card</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=PSA+DNA&categoryID=51&sortBy=price&sortOrder=desc&status=sold">PSA/DNA autographs</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Pokemon&categoryID=220&sortBy=price&sortOrder=desc&status=sold">Pokemon</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Lego&categoryID=220&sortBy=price&sortOrder=desc&status=sold">Lego</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=YUGIOH&categoryID=2536&sortBy=price&sortOrder=desc&status=sold">Yugioh</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=(Magic,MTG)&categoryID=2536&sortBy=price&sortOrder=desc&status=sold">Magic: The Gathering</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Titanic&sortBy=price&sortOrder=desc&status=sold">Titanic</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Gymboree&categoryID=171146&sortBy=price&sortOrder=desc&status=sold">Gymboree</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Nike&sortBy=price&sortOrder=desc&status=sold">Nike</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Lululemon&sortBy=price&sortOrder=desc&status=sold">Lululemon</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=Abercrombie+%26+Fitch&sortBy=price&sortOrder=desc&status=sold">Abercrombie & Fitch</a>&nbsp;&nbsp;
        <a class="whats-hot-link" href="/?keywords=yin+yang&sortBy=price&sortOrder=desc&status=sold">Ying & Yang</a>&nbsp;&nbsp;
    </div>
</template>

<script lang="ts">
import { 
    PropType,
    defineComponent
} from "vue";

export default defineComponent({
    name: 'WhatsHotSold'
});
</script>

<style>
.whats-hot-link {
    margin: 0px 10px;
    color: #0033CC;
    text-decoration: none;
    font-family: Microsoft Sans Serif,Arial,Verdana,Tahoma,Helvetica;
    font-size: 14px !important;
    font-weight: normal;
    display: contents;
}
.whats-hot-link:hover {
    color: #002699;
    text-decoration: underline;
    font-weight: bold;
}
</style>